import { Feature } from '@/features.types';
import Permissions from '../../common/permissions';

const Dashboard = () => import(/* webpackChunkName: "merchant" */ '../views/Dashboard.vue');
const MerchantProposal = () => import(/* webpackChunkName: "merchant" */ '../views/MerchantProposal.vue');
const TransactionView = () => import(/* webpackChunkName: "merchant" */ '../views/transactions/TransactionView.vue');
const TransactionSubscriptionNew = () => import(/* webpackChunkName: "merchant" */ '../views/transactions/TransactionSubscriptionNew.vue');
const UsersIndex = () => import(/* webpackChunkName: "merchant" */ '../views/users/MerchantUsers.vue');
const UsersNew = () => import(/* webpackChunkName: "merchant" */ '../views/users/MerchantUserNew.vue');
const UsersEdit = () => import(/* webpackChunkName: "merchant" */ '../views/users/MerchantUserEdit.vue');
const VirtualTerminal = () => import(/* webpackChunkName: "merchant" */ '../views/virtual-terminal/VirtualTerminal.vue');

const HostedPaymentPageEditor = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentPageEditor.vue');
const HostedPayments = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPayments.vue');
const HostedPaymentPagePreview = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentPagePreview.vue');
// eslint-disable-next-line max-len
// const HostedPaymentNew = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentNew.vue');
// eslint-disable-next-line max-len
// const HostedPaymentView = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentView.vue');
const PaymentPage = () => import(/* webpackChunkName: "payments"  */ '@/payments/views/payment-pages/PaymentPage.vue');
// eslint-disable-next-line max-len
const HostedPaymentEdit = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentEdit.vue');
const HostedPaymentConnect = () => import(/* webpackChunkName: "merchant"  */ '../views/hosted-payments/HostedPaymentConnect.vue');

// eslint-disable-next-line max-len
// const PaymentPreview = () => import(/* webpackChunkName: "merchant"  */ '../views/payment-preview/PaymentPreview.vue');

const Plans = () => import(/* webpackChunkName: "merchant"  */ '../views/plans/Plans.vue');
const PlanNew = () => import(/* webpackChunkName: "merchant"  */ '../views/plans/PlanNew.vue');
const PlanEdit = () => import(/* webpackChunkName: "merchant"  */ '../views/plans/PlanEdit.vue');
const PlanView = () => import(/* webpackChunkName: "merchant"  */ '../views/plans/PlanView.vue');

const SubscriptionNew = () => import(/* webpackChunkName: "merchant"  */ '../views/subscriptions/SubscriptionNew.vue');
const SubscriptionEdit = () => import(/* webpackChunkName: "merchant"  */ '../views/subscriptions/SubscriptionEdit.vue');
const SubscriptionView = () => import(/* webpackChunkName: "merchant"  */ '../views/subscriptions/SubscriptionView.vue');

const Customers = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/Customers.vue');
const CustomerView = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/CustomerView.vue');
const CustomerNew = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/CustomerNew.vue');
const CustomerEdit = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/CustomerEdit.vue');
const CustomerCharge = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/payment-methods/CustomerCharge.vue');
const PaymentMethodNew = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/payment-methods/PaymentMethodNew.vue');

const CustomerBatchUploads = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/batch-uploads/CustomerBatchUploads.vue');
const CustomerBatchUploadView = () => import(/* webpackChunkName: "merchant"  */ '../views/customers/batch-uploads/CustomerBatchUploadView.vue');

const TransactionDetailsReport = () => import(/* webpackChunkName: "merchant" */ '../views/reports/TransactionDetailsReport.vue');
const BatchSettlementsReport = () => import(/* webpackChunkName: "merchant" */ '../views/reports/BatchSettlementsReport.vue');
const RecurringReport = () => import(/* webpackChunkName: "merchant" */ '../views/reports/RecurringReport.vue');
const BillingReport = () => import(/* webpackChunkName: "merchant" */ '../views/reports/BillingReport.vue');
const InvoiceView = () => import(/* webpackChunkName: "merchant" */ '../views/reports/InvoiceView.vue');

const Products = () => import(/* webpackChunkName: "merchant" */ '../views/products/Products.vue');
const ProductNew = () => import(/* webpackChunkName: "merchant"  */ '../views/products/ProductNew.vue');
const ProductEdit = () => import(/* webpackChunkName: "merchant"  */ '../views/products/ProductEdit.vue');

const Categories = () => import(/* webpackChunkName: "merchant" */ '../views/categories/Categories.vue');

const Invoices = () => import(/* webpackChunkName: "merchant" */ '../views/invoices/Invoices.vue');
const InvoiceNew = () => import(/* webpackChunkName: "merchant" */ '../views/invoices/InvoiceNew.vue');
const InvoiceEdit = () => import(/* webpackChunkName: "merchant" */ '../views/invoices/InvoiceEdit.vue');

const CustomFields = () => import(/* webpackChunkName: "merchant" */ '../views/settings/CustomFields.vue');

const BatchView = () => import(/* webpackChunkName: "merchant" */ '../views/batches/BatchView.vue');

export const HPP_PAYMENT_PREVIEW_ROUTE_NAME = 'merchant.hosted-payments.preview';

export const HPP_PAYMENT_PAGES_NEW_ROUTE_NAME = 'merchant.hosted-payments.new';

const InfoFields = () => import(/* webpackChunkName: "merchant" */ '../views/settings/InfoFields.vue');

const ReceiptSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/ReceiptSettings.vue');

const Processors = () => import(/* webpackChunkName: "merchant" */ '../views/settings/Processors.vue');

const ShoppingCarts = () => import(/* webpackChunkName: "merchant"  */ '../views/shopping-carts/ShoppingCarts.vue');

const EmailSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/EmailSettings.vue');

const ShoppingCartNew = () => import(/* webpackChunkName: "merchant" */ '../views/shopping-carts/ShoppingCartNew.vue');

const Level3Settings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/Level3Settings.vue');

const NonCashAdjustmentFee = () => import(/* webpackChunkName: "merchant" */ '../views/settings/NonCashAdjustmentFee.vue');

const ConvenienceFee = () => import(/* webpackChunkName: "merchant" */ '../views/settings/ConvenienceFee.vue');

const TransactionLimits = () => import(/* webpackChunkName: "merchant" */ '../views/settings/TransactionLimits.vue');
const IpBlockers = () => import(/* webpackChunkName: "merchant" */ '../views/settings/IpBlockers.vue');
const UploadLogo = () => import(/* webpackChunkName: "merchant" */ '../views/settings/UploadLogo.vue');

const AvsSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/AvsSettings.vue');
const IpSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/IpSettings.vue');
// const CardAcceptanceSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/CardAcceptanceSettings.vue');

const InvoiceSettings = () => import(/* webpackChunkName: "merchant" */ '../views/settings/InvoiceSettings.vue');

const HelpfulVideos = () => import(/* webpackChunkName: "merchant" */ '../../common/components/support/HelpfulVideos.vue');
const RateLimiter = () => import(/* webpackChunkName: "merchant" */ '../views/settings/RateLimiter.vue');

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/onboarding/proposal',
    name: 'merchant-proposal',
    component: MerchantProposal,
  },
  {
    path: '/virtual-terminal',
    name: 'virtual-terminal',
    component: VirtualTerminal,
  },
  {
    path: '/transactions/:id',
    name: 'transactions.view',
    component: TransactionView,
    props: true,
  },
  {
    path: '/hosted-payments',
    name: 'hosted-payments',
    component: HostedPayments,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  {
    path: '/hosted-payments/preview/:id',
    name: 'hosted-payments.preview',
    component: HostedPaymentPagePreview,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  {
    path: '/hosted-payments/new',
    name: 'hosted-payments.new',
    component: HostedPaymentPageEditor,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  // {
  //   path: '/hosted-payments/payment-preview',
  //   name: 'hosted-payments.payment-preview',
  //   component: PaymentPreview,
  //   props: (route) => ({
  //     includeShipping: route.query.includeShipping === 'true',
  //     billingCompany: route.query.billingCompany,
  //     billingFirstName: route.query.billingFirstName,
  //     billingLastName: route.query.billingLastName,
  //     billingEmail: route.query.billingEmail,
  //     billingAddress1: route.query.billingAddress1,
  //     billingAddress2: route.query.billingAddress2,
  //     billingCity: route.query.billingCity,
  //     billingState: route.query.billingState,
  //     billingZip: route.query.billingZip,
  //     billingCountry: route.query.billingCountry,
  //     billingPhone: route.query.billingPhone,
  //     billingFax: route.query.billingFax,
  //     shippingCompany: route.query.shippingCompany,
  //     shippingFirstName: route.query.shippingFirstName,
  //     shippingLastName: route.query.shippingLastName,
  //     shippingEmail: route.query.shippingEmail,
  //     shippingAddress1: route.query.shippingAddress1,
  //     shippingAddress2: route.query.shippingAddress2,
  //     shippingCity: route.query.shippingCity,
  //     shippingState: route.query.shippingState,
  //     shippingZip: route.query.shippingZip,
  //     shippingCountry: route.query.shippingCountry,
  //     shippingPhone: route.query.shippingPhone,
  //     hasCreditCardProcessor: route.query.hasCreditCardProcessor === 'true',
  //     hasAchProcessor: route.query.hasAchProcessor === 'true',
  //     fixedAmount: route.query.fixedAmount ? parseInt(route.query.fixedAmount, 10) : null,
  //     description: route.query.description,
  //   }),
  //   meta: {
  //     featureFlipping: { key: Feature.HPP },
  //   },
  // },
  {
    path: '/hosted-payments/:id',
    name: 'hosted-payments.view',
    component: PaymentPage,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  {
    path: '/hosted-payments/:id/edit',
    name: 'hosted-payments.edit',
    component: HostedPaymentEdit,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  {
    path: '/hosted-payments/:id/connect',
    name: 'hosted-payments.connect',
    component: HostedPaymentConnect,
    props: true,
    meta: {
      featureFlipping: { key: Feature.HPP },
      permission: Permissions.MANAGE_HOSTED_PAYMENT_PAGES,
    },
  },
  {
    path: '/transactions/:id/subscriptions/new',
    name: 'transactions.subscriptions.new',
    component: TransactionSubscriptionNew,
    props: true,
  },
  {
    path: '/customers',
    name: 'customers.index',
    component: Customers,
    meta: {
      permission: Permissions.MANAGE_CUSTOMER_VAULT,
    },
  },
  {
    path: '/customers/new',
    name: 'customers.new',
    component: CustomerNew,
    meta: {
      permission: Permissions.MANAGE_CUSTOMER_VAULT,
    },
  },
  {
    path: '/customers/uploads',
    name: 'customers.batch.uploads.index',
    component: CustomerBatchUploads,
  },
  {
    path: '/customers/uploads/:id',
    name: 'customers.batch.uploads.view',
    component: CustomerBatchUploadView,
    props: true,
  },
  {
    path: '/customers/:id/edit',
    name: 'customers.edit',
    component: CustomerEdit,
    meta: {
      permission: Permissions.MANAGE_CUSTOMER_VAULT,
    },
    props: true,
  },
  {
    path: '/customers/:id',
    name: 'customers.view',
    component: CustomerView,
    meta: {
      permission: Permissions.MANAGE_CUSTOMER_VAULT,
    },
    props: true,
  },
  {
    path: '/customers/:customerId/payment-methods/new',
    name: 'customers.payment-methods.new',
    component: PaymentMethodNew,
    props: true,
  },
  {
    path: '/customers/:customerId/payment-methods/:paymentMethodId/charge',
    name: 'customers.payment-methods.charge',
    component: CustomerCharge,
    props: true,
  },
  {
    path: '/plans',
    name: 'plans.index',
    component: Plans,
  },
  {
    path: '/plans/new',
    name: 'plans.new',
    component: PlanNew,
  },
  {
    path: '/plans/:id/edit',
    name: 'plans.edit',
    component: PlanEdit,
    props: true,
  },
  {
    path: '/plans/:id',
    name: 'plans.view',
    component: PlanView,
    props: true,
  },
  {
    path: '/subscriptions/new',
    name: 'subscriptions.new',
    component: SubscriptionNew,
  },
  {
    path: '/subscriptions/:id/edit',
    name: 'subscriptions.edit',
    component: SubscriptionEdit,
    props: true,
  },
  {
    path: '/subscriptions/:id',
    name: 'subscriptions.view',
    component: SubscriptionView,
    props: true,
  },
  {
    path: '/batches/:id',
    name: 'batches.view',
    component: BatchView,
    props: true,
  },
  {
    path: '/users',
    name: 'users.index',
    component: UsersIndex,
  },
  {
    path: '/users/new',
    name: 'users.new',
    component: UsersNew,
    props: true,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: UsersEdit,
    props: true,
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.transactions.details' },
  },
  {
    path: '/reports/transaction-details',
    name: 'reports.transactions.details',
    component: TransactionDetailsReport,
  },
  {
    path: '/reports/batch-settlements',
    name: 'reports.batch.settlements',
    component: BatchSettlementsReport,
  },
  {
    path: '/reports/recurring',
    name: 'reports.recurring',
    component: RecurringReport,
  },
  {
    path: '/reports/billing',
    name: 'reports.billing.details',
    component: BillingReport,
  },
  {
    path: '/invoice/:invoiceNumber',
    name: 'InvoiceView',
    component: InvoiceView,
  },
  {
    path: '/products',
    name: 'products.index',
    component: Products,
  },
  {
    path: '/products/new',
    name: 'products.new',
    component: ProductNew,
  },
  {
    path: '/products/:id/edit',
    name: 'products.edit',
    component: ProductEdit,
    props: true,
  },
  {
    path: '/categories',
    name: 'categories.index',
    component: Categories,
  },
  {
    path: '/invoices',
    name: 'invoices.index',
    component: Invoices,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
  },
  {
    path: '/invoices/new',
    name: 'invoices.new',
    component: InvoiceNew,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
  },
  {
    path: '/invoices/:id/edit',
    name: 'invoices.edit',
    component: InvoiceEdit,
    meta: {
      permission: Permissions.MANAGE_INVOICING,
    },
    props: true,
  },
  {
    path: '/settings/custom-fields',
    name: 'settings.custom-fields',
    component: CustomFields,
  },
  {
    path: '/settings/info-fields',
    name: 'settings.info-fields',
    component: InfoFields,
  },
  {
    path: '/settings/receipt-settings',
    name: 'settings.receipt-settings',
    component: ReceiptSettings,
  },
  {
    path: '/settings/processors',
    name: 'settings.processors',
    component: Processors,
  },
  {
    path: '/shopping-carts',
    name: 'shopping-carts',
    component: ShoppingCarts,
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
  {
    path: '/settings/email-settings',
    name: 'settings.email-settings',
    component: EmailSettings,
  },
  {
    path: '/settings/cash-discount',
    name: 'settings./cash-discount',
    component: NonCashAdjustmentFee,
  },
  {
    path: '/settings/convenience-fee',
    name: 'settings./convenience-fee',
    component: ConvenienceFee,
  },
  {
    path: '/shopping-carts/new',
    name: 'shopping-carts.new',
    component: ShoppingCartNew,
    meta: {
      featureFlipping: { key: Feature.SHOPPING_CARTS, defaut: false },
    },
  },
  {
    path: '/settings/level-3-settings',
    name: 'settings.level-3-settings',
    component: Level3Settings,
  },
  {
    path: '/settings/transaction-ip-blocker',
    name: 'settings.ip-blocker',
    component: IpBlockers,
  },
  {
    path: '/settings/upload-logo',
    name: 'settings.upload-logo',
    component: UploadLogo,
  },
  {
    path: '/settings/avs-settings',
    name: 'settings.avs-settings',
    component: AvsSettings,
  },
  {
    path: '/settings/transaction-limits',
    name: 'settings.transaction-limits',
    component: TransactionLimits,
  },
  {
    path: '/settings/ip-settings',
    name: 'settings.ip-settings',
    component: IpSettings,
  },
  // {
  //   path: '/settings/card-acceptance',
  //   name: 'settings.card-acceptance',
  //   component: CardAcceptanceSettings,
  // },
  {
    path: '/settings/invoice-settings',
    name: 'settings.invoice-settings',
    component: InvoiceSettings,
  },
  {
    path: '/settings/rate-limiter',
    name: 'settings.rate-limiter',
    component: RateLimiter,
  },
  {
    path: '/helpful-videos',
    name: 'helpful-videos',
    component: HelpfulVideos,
  },
];
